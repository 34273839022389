<template>
  <div class="teilnahme pt-20 lg:px-52 text-primary-soft-white">
    <h1 class="pt-10 text-2xl lg:text-5xl font-bold">
      TEILNAHMEBEDINGUNGEN <br />
      IQOS CLUB TogetherXMusic – IQOS Unexpected Voices
    </h1>
    <h3>1. Aktivität: IQOS TogetherXMusic – IQOS Unexpected Voices</h3>
    <ul>
      <li>
        1.1.&ensp;Die Aktivität IQOS Unexpected Voices
        (https://de.iqosclub.com/togetherx und
        https://de.iqosclub.com/togetherx/application) ist ein Bestandteil der
        Kampagne IQOS TogetherXMusic. Im Rahmen dieser Kampagne können sich IQOS
        CLUB Mitglieder (im Folgenden gelten Personenbezeichnungen gleichermaßen
        für Personen jeden Geschlechts) im IQOS CLUB für die Teilnahme an einem
        von 3 Chören bewerben. Im Rahmen der Bewerbung und der Kampagne, können
        die Teilnehmer von allen IQOS CLUB Mitgliedern in einen der Chöre
        gewählt werden.
      </li>

      <li>
        <br />Bei einer erfolgreichen Bewerbung als Teil des Chors werden die
        Teilnehmer mit ihrer Zustimmung im weiteren Verlauf fotografisch und
        filmisch begleitet. Der Höhepunkt ist ein Auftritt auf einem von 3
        Festivals in Deutschland.
      </li>
      <li>
        <br />Für jede Bewerbung erhalten die Teilnehmer einmalig 150 IQOS
        Points. Die 150 IQOS Points werden nach erfolgreicher Prüfung und
        Freigabe der Bewerbung gutgeschrieben. Die Prüfung des Materials kann
        bis zu 48h dauern. Die Kampagne IQOS TogetherXMusic wird veranstaltet
        von IQOS, einem Geschäftsbereich der Philip Morris GmbH, Am Haag 14,
        82166 Gräfelfing, im Folgenden „Veranstalter“ genannt.
      </li>
      <li>
        <br />1.2.&ensp;Im Zeitraum von 11.04.2024 bis zum 07.05.2024 können
        IQOS CLUB Mitglieder in der Bewerbungsphase über
        https://de.iqosclub.com/togetherx/application ihren Beitrag einreichen.
        Dieser Beitrag wird nach Prüfung und sofern dieser den Richtlinien
        entspricht, im IQOS CLUB auf den IQOS TogetherXMusic – Seiten (
        https://de.iqosclub.com/togetherx) veröffentlicht. Ein Beitrag setzt
        sich zusammen aus einem kurzen Video, Foto, Text sowie die Bestimmung
        der Stimmlage.
      </li>
      <li>
        <br />IQOS CLUB Mitglieder, welche einen Beitrag einreichen, werden im
        weiteren Verlauf „Teilnehmer“ genannt. Die Teilnahme ist freiwillig,
        kostenlos und nicht an eine Kaufverpflichtung gekoppelt. Die Teilnehmer
        müssen lediglich die Kosten tragen, die mit der Teilnahme über das
        Internet verbunden sind (Nutzungsentgelt für Internetverbindung).
      </li>
    </ul>

    <h3>2. Ablauf</h3>
    <ul>
      <li>
        2.1.&ensp;Um einen Beitrag einzureichen, muss die Anmeldung im IQOS CLUB
        unter https://www.iqos.com/ erfolgen. Voraussetzung hierfür ist ein
        Konto mit registriertem Gerät auf iqos.com. Anschließend haben IQOS CLUB
        Mitglieder die Möglichkeit, einen Beitrag in Form von einem kurzen
        Video, Foto, Text sowie die Bestimmung der Stimmlage einzureichen.
      </li>

      <li>
        <br />2.2.&ensp;Teilnahmeberechtigt sind Personen mit Wohnsitz in der
        Bundesrepublik Deutschland, die das 18. Lebensjahr vollendet haben und
        in der IQOS Datenbank registriert sind. Von der Teilnahme ausgeschlossen
        sind Mitarbeiter der Philip Morris GmbH und deren Angehörige, sowie die
        Mitarbeiter verbundener Agenturen.
      </li>
      <li>
        <br />2.3.&ensp; Die Anzahl der pro Teilnehmer eingereichten Beiträge
        ist auf einen limitiert. Die eingereichten Beiträge werden vom
        Veranstalter überprüft und erscheinen erst nach erfolgreicher Prüfung
        und Freigabe auf der Webseite. Der Veranstalter behält sich vor, einen
        Beitrag nur aufgrund der Nichteinhaltung der in der
        Veröffentlichungsfreigabe festgelegten Richtlinien abzulehnen. Der
        Teilnehmer wird nach Freigabe oder Ablehnung per E-Mail informiert. Eine
        mehrfache Einreichung ist nicht erlaubt und unzulässig. Eine Übersicht
        der Richtlinien für das Hochladen eines Beitrags und die Freigabe ist in
        der Veröffentlichungsfreigabe
        (https://de.iqosclub.com/togetherx/application/veroeffentlichungsfreigabe)
        enthalten.
      </li>
      <li>
        <br />Sollte der Beitrag ausgewählt werden, wird das eingereichte
        Material im IQOS CLUB und dessen Unterseiten sowie gegebenfalls in den
        Offline-Kanälen des Veranstalters (weitere Informationen hierzu in der
        Veröffentlichungsfreigabe
        https://de.iqosclub.com/togetherx/application/veroeffentlichungsfreigabe)
        publiziert.
      </li>
      <li>
        <br />2.4.&ensp;Mit Einreichung des Beitrages und Freigabe zur
        Veröffentlichung, nimmt der Teilnehmer automatisch an der Bewerbung und
        Voting für einen der Chöre teil. IQOS CLUB Mitglieder können, unabhängig
        davon, ob sie einen Beitrag eingereicht haben, im Zeitraum von 07.05. –
        20.05.2024 (Der Veranstalter behält sich das Recht vor den Zeitraum zu
        verlängern oder zu verkürzen). Likes vergeben, indem sie auf den
        Herz-Button im jeweiligen Beitrag des Teilnehmers klicken. Mit Vergabe
        von diesen Likes können Teilnehmer von IQOS CLUB Mitgliedern in den Chor
        gewählt werden.
      </li>
      <li>
        <br />Jedes IQOS CLUB Mitglied kann pro Tag 3 Stimmen abgeben, für
        welche Sie jeweils 10 Statuspunkte gutgeschrieben bekommt.
      </li>
      <li>
        <br />Die Teilnehmer mit den meisten Likes werden am Ende der Voting
        Phase Teil des Chores sein und von uns telefonisch oder per E-Mail
        kontaktiert. Im weiteren Verlauf werden diese fotografisch und filmisch
        begleitet, u.a. auf den Trainigscamp der Chöre. Voraussetzung für den
        Festivalauftritt ist die Teilnahme am Trainingscamp von 06. bis 10.
        Juni. Höhepunkt ist ein Auftritt auf einem von drei Festivals in
        Deutschland. Weitere Details zu Auftritten und zum weiteren Verlauf
        werden den Chormitgliedern bei Bestätigung der Teilnahme mitgegeben.
      </li>
      <li>
        <br />2.5.&ensp;Eine Teilnahme über Gewinnspiel-Services, automatisierte
        Massenteilnahmeverfahren Dritter oder sonstige Manipulationen ist nicht
        gestattet.
      </li>
      <li>
        <br />2.6.&ensp;Bei einem Verstoß gegen diese Teilnahmebedingungen
        behält sich der Veranstalter das Recht vor, den Teilnehmer von der
        Aktivität auszuschließen. Gegebenenfalls können in diesen Fällen
        nachträglich Gewinne aberkannt und zurückgefordert werden.
      </li>
    </ul>

    <h3>3. Datenschutz/Einwilligung zur Nutzung personenbezogener Daten</h3>
    <ul>
      <li>
        3.1.&ensp;Zur Durchführung der Aktivität verarbeitet Philip Morris und
        von Philip Morris beauftragte Agenturen personenbezogenen Daten des
        Teilnehmers – insbesondere das entstehende Bild- und Tonmaterial nur
        soweit dies erforderlich ist.
      </li>

      <li>
        <br />3.2.&ensp;Eine Übermittlung der personenbezogenen Daten an
        sonstige Dritte oder eine Nutzung der Daten zu sonstigen Werbezwecken
        ohne die Einwilligung des Teilnehmers findet nicht statt, es sei denn,
        dass die PMG oder deren Agenturen gesetzlich zur Herausgabe von Daten
        verpflichtet sind oder dass sie zur Durchsetzung der Forderungen zur
        Berufsverschwiegenheit verpflichtete Dritte einschalten.
      </li>
      <li>
        <br />3.3.&ensp; Weitere Einzelheiten bezüglich der Verarbeitung und
        Nutzung der personenbezogenen Daten bei der Teilnahme an unseren
        Aktivitäten erfährt der Teilnehmer in der abrufbaren
        Datenschutzerklärung auf iqos.com
        <span>(https://www.pmiprivacy.com/germany/de/consumer/)</span>
      </li>
      <li>
        <br />3.4.&ensp;Des Weiteren gelten die Bestimmungen der
        Veröffentlichungsfreigabe und dieser Teilnahmebedingungen, welchen der
        Teilnehmer durch Setzen eines Häckchens bei Upload zustimmt.
      </li>
    </ul>

    <h3>4. Haftungsbeschränkung</h3>
    <ul>
      <li>
        4.1.&ensp;Alle Angaben seitens des Veranstalters, insbesondere im
        Zusammenhang mit der Veröffentlichung von Gewinnernamen etc. erfolgen
        ohne Gewähr.
      </li>

      <li>
        <br />4.2.&ensp;Soweit vom Gewinner Ansprüche im Zusammenhang mit den
        erhaltenen Gewinnen geltend gemacht werden, sind diese, soweit rechtlich
        zulässig, unmittelbar gegen den Hersteller/Händler, Lieferanten,
        Reiseveranstalter oder sonstigen Leistungsträger zu richten. Sollten
        diesbezügliche Ansprüche bei dem Veranstalter entstehen, werden diese an
        die jeweiligen Gewinner abgetreten.
      </li>
      <li>
        <br />4.3.&ensp;Der Veranstalter haftet auf Schadenersatz - gleich aus
        welchem Rechtsgrund - nur bei Vorsatz und grober Fahrlässigkeit des
        Veranstalters, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen
        sowie bei schuldhafter Verletzung wesentlicher Vertragspflichten.
        Wesentliche Vertragspflichten sind solche, die die Durchführung dieses
        Gewinnspiels überhaupt erst ermöglichen und auf die der Teilnehmer
        vertrauen durfte und vertraut hat. Bei nur leicht fahrlässiger
        Verletzung wesentlicher Vertragspflichten ist die Haftung begrenzt auf
        den Ersatz des vertragstypischen, zum Zeitpunkt der Nutzung der
        Aktivitätsseite vorhersehbaren Schadens. Diese Haftungsbeschränkung gilt
        nicht für von Philip Morris, ihren gesetzlichen Vertretern oder ihren
        Erfüllungsgehilfen schuldhaft verursachten Schäden aus der Verletzung
        des Lebens, des Körpers oder der Gesundheit sowie für die Haftung nach
        dem Produkthaftungsgesetz.
      </li>
      <li>
        <br />4.4.&ensp;Voranstehende Haftungsbeschränkung gilt insbesondere für
        Schäden, durch Fehler, Verzögerungen oder Unterbrechungen in der
        Übermittlung von Daten o. ä., bei Störungen der technischen Anlagen oder
        des Services, unrichtige Inhalte, Verlust oder Löschung von Daten,
        Viren.
      </li>
    </ul>

    <h3>5. Sonstiges</h3>
    <ul>
      <li>5.1.&ensp;Das Recht der Bundesrepublik Deutschland ist anwendbar.</li>

      <li>
        <br />5.2.&ensp;Sollten einzelne dieser Bestimmungen ungültig sein oder
        werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon
        unberührt.
      </li>
      <li>
        <br />5.3.&ensp;Der Veranstalter behält sich das Recht vor, die
        Teilnahmebedingungen – im Rahmen des rechtlich Zulässigen - jederzeit zu
        ändern oder zu korrigieren.
      </li>
      <li>
        <br />5.4.&ensp;Der Veranstalter behält sich vor, das Gewinnspiel bei
        Vorliegen eines wichtigen Grundes (z.B. Viren im Computersystem,
        Manipulation oder Fehler in der Anwendung) zu jedem Zeitpunkt ohne
        Vorankündigung abzubrechen.
      </li>
      <li><br />5.5.&ensp;Der Rechtsweg ist ausgeschlossen</li>
    </ul>

    <br />
    <br />
    <p>Stand: 17/04/2024</p>
    <br />
    <br />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.teilnahme {
  background-color: #1e1b23;
}
h1,
h2,
h3,
h4 {
  @apply font-bold pb-4 pt-8 px-2 md:px-5;
}
ol {
  @apply list-none pl-4 px-2 md:px-5;
}
ul {
  @apply list-none pl-4 px-2 md:px-5;
}
li {
  @apply mb-1 px-2 md:px-5 break-words;
}
p {
  @apply mb-1 last:mb-0 pb-1 px-2 md:px-5;
}
</style>
